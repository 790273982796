<template>
  <main>
    <div id="printContainer" class="main-grid">
      <div class="aside">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ name: 'home' }">
            <font-awesome-icon :icon="[ 'fas', 'home' ]" />
          </el-breadcrumb-item>
          <el-breadcrumb-item>比較人物</el-breadcrumb-item>
        </el-breadcrumb>
        <h4>比較人物</h4>
        <div class="separate-title">依比較人物呈現</div>
        <ul
          class="list list--aside"
          v-loading="isPersonDataLoading">
          <li
            class="item"
            :key="item.index"
            v-for="item in sideMenuPersonList">
            <router-link
              class="link"
              :class="{active:item.isActive}"
              :to="item.route">
              {{item.label}}
            </router-link>
          </li>
        </ul>
        <div class="separate-title">依比較項目呈現</div>
        <ul
          class="list list--aside"
          v-loading="isPersonDataLoading">
          <li
            class="item"
            :key="item.index"
            v-for="item in sideMenuItemList">
            <router-link
              class="link"
              :class="{active:item.isActive}"
              :to="item.route">
              {{item.label}}
            </router-link>
          </li>
        </ul>
        <section>
          <RecentQuery v-if="isShowRecentQuery">
          </RecentQuery>
        </section>
      </div>
      <!-- <div
        class="container"
        :is="pageComponent"
        :menuIndex="menuIndex">
      </div> -->
      <router-view class="container"></router-view>
    </div>
    <MainPageFloatButton>
    </MainPageFloatButton>
  </main>
</template>

<script>
import routerService from '@/utils/routerService.js'
import moment from 'moment'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import * as codes from '@/const/codes.ts'
import recentQueryStorage from '@/storage/recentQueryStorage.js'
import RecentQuery from '@/components/RecentQuery/RecentQuery.vue'
import MainPageFloatButton from '@/components/MainPageFloatButton/MainPageFloatButton.vue'
// import ComparePersonPage from './ComparePersonPage.vue'
// import CompareItemPage from './CompareItemPage.vue'

export default {
  components: {
    RecentQuery,
    MainPageFloatButton
    // ComparePersonPage,
    // CompareItemPage
  },
  data () {
    return {
      isShowRecentQuery: false
    }
  },
  computed: {
    ...mapState([
      'originRouteQuery'
    ]),
    ...mapState('multiPersonDetail', [
      'basicList',
      'getList',
      'isPersonDataLoading',
      'isPersonNewsLoading',
      'multiPersonData',
      'multiPersonNews'
    ]),
    ...mapGetters([
      'getterLimitD',
      'getterLimitE',
      'getterLimitF',
      'getterLimitG',
    ]),
    menuIndex () {
      if (this.$route.params && this.$route.params.menuIndex) {
        return this.$route.params.menuIndex
      } else {
        return null
      }
    },
    // pageComponent () {
    //   if (this.multiPersonData.length) {
    //     const findItem = this.basicList.find(d => d.keyword == this.menuIndex)
    //     if (findItem) {
    //       return 'ComparePersonPage'
    //     } else {
    //       return 'CompareItemPage'
    //     }
    //   } else {
    //     return null
    //   }
    // },
    // 側邊比較公司選單
    sideMenuPersonList () {
      let personList = []
      if (this.basicList.length) {
        personList = this.basicList.map(d => {
          return {
            label: d.keyword,
            route: {
              name: 'multiPersonDetailByPerson',
              params: {
                menuIndex: d.keyword
              },
              query: this.originRouteQuery
            },
            isActive: d.keyword == this.menuIndex,
            index: d.keyword
          }
        })
      }
      return personList
    },
    // 側邊比較項目選單
    sideMenuItemList () {
      let getList = []
      if (this.getList.length) {
        getList = this.getList.map(d => {
          return {
            label: codes.PERSON_DETAIL_ITEM_DICT[d],
            route: {
              name: 'multiPersonDetailByItem',
              params: {
                menuIndex: d
              },
              query: this.originRouteQuery
            },
            isActive: d === this.menuIndex,
            index: d
          }
        })
      }
      return getList
    },
  },
  methods: {
    ...mapActions('multiPersonDetail', [
      'actionMultiPersonNews',
      'actionMultiPersonDetail'
    ]),
    ...mapMutations('multiPersonDetail', [
      'mutationBasicList',
      'mutationGetList'
    ])
  },
  async created () {
    // 取得網址參數
    const basicList = JSON.parse(this.originRouteQuery.basicList)
    let get = JSON.parse(this.originRouteQuery.get)
    // -- 刪除無權限的get條件（非正常操作情況，預防由網址直接開啟網頁） --
    // 基本資料
    if (this.getterLimitD === false) {
      delete get.shareholders
      delete get.managers
      delete get.company_branchs
      delete get.factorys
    }
    // 風險指標
    if (this.getterLimitE === false) {
      delete get.verdicts
      // delete get.news
      delete get.disputes
    }
    // 經營指標
    if (this.getterLimitF === false) {
      delete get.trades
      delete get.procurements
      delete get.patents
      delete get.awards
    }
    // 財務指標
    if (this.getterLimitG === false) {
      delete get.revenue_history
      delete get.ifrss_summary
    }

    // 紀錄公司詳細資料清單
    let getList = []
    Object.keys(get).forEach(d => getList.push(d))
    this.mutationBasicList(basicList)
    this.mutationGetList(getList)

    // 判斷是否有新聞資料
    let isGetNews = getList.includes('news')

    // -- 查詢 --
    if (isGetNews) {
      let getWithoutNews = JSON.parse(JSON.stringify(get))
      delete getWithoutNews.news
      this.actionMultiPersonNews(basicList)
      await this.actionMultiPersonDetail({
        basicList,
        get: getWithoutNews
      })
    } else {
      await this.actionMultiPersonDetail({
        basicList,
        get
      })
    }

    // -- 紀錄最近查詢 --
    try {
      let keyword = ''
      if (this.sideMenuPersonList.length) {
        keyword = this.sideMenuPersonList.map(d => d.label).join(',')
      } else {
        return
      }
      // 存入localStorage
      recentQueryStorage.insert(keyword, this.$route)
      // 顯示
      this.isShowRecentQuery = true
    }
    catch (e) {
    }
  },
  watch: {
    '$route.query': {
      handler (q) {
        // 如果網址參數是原始參數的話，重新再導向到短網址
        if (this.$route.query.basicList && this.$route.query.get) {
          routerService('replace', {
            name: this.$route.name,
            params: this.$route.params,
            query: this.$route.query
          })
          return
        }
      },
      immediate: true
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.$route.params.menuIndex) {
        routerService('replace', { name: 'home' })
        return
      }
      if (!vm.$route.query.query) {
        routerService('replace', { name: 'home' })
        return
      }
      if (vm.getterLimitD === false &&
      vm.getterLimitE === false &&
      vm.getterLimitF === false &&
      vm.getterLimitG === false) {
        // 全無權限導回首頁
        routerService('replace', { name: 'home' })
        return
      }
    })
  }
}
</script>
